exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-5-razlogov-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/5-razlogov/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-5-razlogov-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-5-razlogov-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/5-razlogov/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-5-razlogov-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-cena-soncne-elektrarne-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/cena-soncne-elektrarne/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-cena-soncne-elektrarne-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-cena-soncne-elektrarne-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/cena-soncne-elektrarne/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-cena-soncne-elektrarne-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-delovanje-elektrarn-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/delovanje-elektrarn/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-delovanje-elektrarn-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-delovanje-elektrarn-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/delovanje-elektrarn/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-delovanje-elektrarn-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-energetska-skupnost-soncna-sola-hrastnik-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/energetska-skupnost-soncna-sola-hrastnik/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-energetska-skupnost-soncna-sola-hrastnik-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-energetska-skupnost-soncna-sola-hrastnik-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/energetska-skupnost-soncna-sola-hrastnik/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-energetska-skupnost-soncna-sola-hrastnik-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-energetski-hranilniki-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/energetski-hranilniki/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-energetski-hranilniki-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-energetski-hranilniki-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/energetski-hranilniki/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-energetski-hranilniki-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-fotovoltaika-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/fotovoltaika/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-fotovoltaika-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-fotovoltaika-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/fotovoltaika/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-fotovoltaika-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-mikro-balkonska-soncna-elektrarna-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/mikro-balkonska-soncna-elektrarna/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-mikro-balkonska-soncna-elektrarna-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-mikro-balkonska-soncna-elektrarna-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/mikro-balkonska-soncna-elektrarna/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-mikro-balkonska-soncna-elektrarna-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-net-metering-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/net-metering/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-net-metering-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-postopek-postavitve-elektrarne-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/postopek-postavitve-elektrarne/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-postopek-postavitve-elektrarne-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-postopek-postavitve-elektrarne-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/postopek-postavitve-elektrarne/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-postopek-postavitve-elektrarne-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-postopek-postavitve-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/postopek-postavitve/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-postopek-postavitve-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-postopek-postavitve-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/postopek-postavitve/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-postopek-postavitve-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-stroski-in-donos-mikro-elektrarne-index-en-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/stroski-in-donos-mikro-elektrarne/index.en.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-stroski-in-donos-mikro-elektrarne-index-en-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-stroski-in-donos-mikro-elektrarne-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/blog/stroski-in-donos-mikro-elektrarne/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-blog-stroski-in-donos-mikro-elektrarne-index-mdx" */),
  "component---src-pages-energetska-skupnost-js": () => import("./../../../src/pages/energetska-skupnost.js" /* webpackChunkName: "component---src-pages-energetska-skupnost-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrija-js": () => import("./../../../src/pages/industrija.js" /* webpackChunkName: "component---src-pages-industrija-js" */),
  "component---src-pages-kisik-js": () => import("./../../../src/pages/kisik.js" /* webpackChunkName: "component---src-pages-kisik-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-mikro-elektrarna-js": () => import("./../../../src/pages/mikro-elektrarna.js" /* webpackChunkName: "component---src-pages-mikro-elektrarna-js" */),
  "component---src-pages-soncna-elektrarna-js": () => import("./../../../src/pages/soncna-elektrarna.js" /* webpackChunkName: "component---src-pages-soncna-elektrarna-js" */),
  "component---src-pages-vzdrzevanje-svetovanje-js": () => import("./../../../src/pages/vzdrzevanje-svetovanje.js" /* webpackChunkName: "component---src-pages-vzdrzevanje-svetovanje-js" */)
}

